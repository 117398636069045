import Hummingbird from './hummingbird/core'

// Note: The number in the filename is the order in which the plugin is loaded.
// As per Nuxt recommendation: https://nuxt.com/docs/guide/directory-structure/plugins#plugin-registration-order

export default defineNuxtPlugin({
  name: 'hummingbird',
  setup({ $i18n, provide }) {
    const headers = useRequestHeaders(['host'])
    const host = headers.host || window.location.host
    const { NEXTORY_API_ENDPOINT } = useRuntimeConfig().public

    provide(
      'hummingbird',
      new Hummingbird(
        host,
        NEXTORY_API_ENDPOINT,
        $i18n.localeProperties.value.language
      )
    )
  },
})
