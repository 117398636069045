<template>
  <div>
    <NxHeader />

    <NxErrors :error="error" />

    <SkipHydration>
      <LazyNxFooter />
    </SkipHydration>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import head from '~/mixins/head'
import tracker from '~/mixins/tracker'

export default defineComponent({
  mixins: [head, tracker],

  props: {
    error: { type: Object, default: null },
  },

  setup() {
    // Nuxt 3 migration: head() can't be automatically called from the mixin, so we need to call it manually for now
    useHead(head.head())
  },
})
</script>
